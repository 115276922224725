<template>
  <div class="content-box">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="设备型号配置" name="first">
        <div class="content-header">
          <el-select
            clearable
            v-model="queryForm.parentId"
            class="input"
            :filterable="false"
            placeholder="设备类型"
            @change="deviceTypeChange"
          >
            <el-option
              v-for="item in firstCategoryList"
              :key="item.id"
              :label="item.deviceName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            clearable
            v-model="queryForm.deviceClassId"
            :disabled="!queryForm.parentId"
            class="input"
            :filterable="false"
            placeholder="设备分类"
          >
            <el-option
              v-for="item in deviceCategoryOptions"
              :key="item.id"
              :label="item.deviceClassName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="queryForm.deviceName"
            class="input"
            clearable
            placeholder="设备型号"
          ></el-input>
          <el-button type="plain" class="cotent-btn" @click="getDeviceSubTypeList">搜索</el-button>
          <el-button type="plain" class="cotent-btn" @click="handleEdit"
            >添加设备型号</el-button
          >
        </div>
        <div class="table-list">
          <el-table
            :data="deviceSubTypeList"
            style="width: 100%"
            :header-cell-style="headerCellStyle"
            border
            v-loading="loading"
            :cell-style="cellStyle"
          >
            <el-table-column prop="id" label="序号"> </el-table-column>
            <el-table-column prop="parentDeviceName" label="设备类型">
            </el-table-column>
            <el-table-column prop="deviceClassName" label="设备分类">
            </el-table-column>
            <el-table-column prop="deviceName" label="设备型号">
            </el-table-column>
            <el-table-column prop="runtime" label="运行时间(分钟)">
            </el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column prop="modifiedBy" label="操作人"></el-table-column>
            <el-table-column prop="updateTime" label="最后操作时间"></el-table-column>
            <el-table-column label="操作" width="180" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
            title="新增/编辑设备型号"
            :visible.sync="dialogVisible"
            width="560px"
            :before-close="dialogClose"
          >
            <div class="edit-box">
              <el-form
                label-position="left"
                label-width="150px"
                :model="formData"
                ref="validateForm"
                :rules="rules"
              >
                <el-form-item label="设备类型:" prop="deviceTypeId">
                  <el-select
                    v-model="formData.deviceTypeId"
                    class="dialogInput"
                    :filterable="false"
                    placeholder="选择设备类型"
                    @change="deviceTypeIdChange"
                    :disabled="formData.id"
                  >
                    <el-option
                      v-for="item in firstCategoryList"
                      :key="item.id"
                      :label="item.deviceName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="设备分类:" prop="deviceClassId">
                  <el-select
                    v-model="formData.deviceClassId"
                    class="dialogInput"
                    :filterable="false"
                    placeholder="选择设备分类"
                    :disabled="!formData.deviceTypeId || formData.id"
                  >
                    <el-option
                      v-for="item in secondCategoryList"
                      :key="item.id"
                      :label="item.deviceClassName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="设备型号:" prop="deviceName">
                  <el-input
                    v-model="formData.deviceName"
                    class="dialogInput"
                    placeholder="请输入设备型号"
                    maxlength="30"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="运行时间(分钟):" prop="runtime">
                  <el-input
                    v-model="formData.runtime"
                    class="dialogInput"
                    type="number"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注:" prop="remark">
                  <el-input
                    v-model="formData.remark"
                    class="dialogInput"
                    type="textarea"
                    maxlength="64"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogClose">取消</el-button>
                <el-button type="primary" @click="dialogConfirm" :loading="confirmBtnLoading"
                  >提交</el-button
                >
              </div>
            </div>
          </el-dialog>
        </div>
      </el-tab-pane>
      <el-tab-pane label="设备类型分类" name="second">
        <DeviceTypeCategory ref="DeviceTypeCategory" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Watch, Mixins, Component, Ref } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";

import {
  getDeviceTypeList,
  getDeviceSubTypeList,
  getMainDeviceTypeList,
  getSubDeviceTypeList,
  saveOrUpdateDeviceType,
  getDeviceByName
} from "@/api/deviceType";

import DeviceTypeCategory from "./components/deviceTypeCategory.vue";

const initFormData = {
  deviceName: "",
  deviceTypeId: "",
  remark: "",
  runtime: 0,
};

@Component({
  components: { DeviceTypeCategory },
})
export default class StoreList extends Mixins(loading, tablePagination) {
  @Ref("DeviceTypeCategory") DeviceTypeCategoryRef;

  activeName = "first";
  queryForm = {
    parentId: "",
    deviceClassId: "",
    deviceName: "",
  }
  deviceCategoryOptions = [];

  deviceTypeList = [];

  deviceSubTypeList = [];

  itemTotal = 0;
  dialogVisible = false;
  formData = {
    id: "",
    deviceTypeId: "",
    deviceClassId: "",
    deviceClassName: "",
    deviceName: "",
    remark: "",
    runtime: 0,
  };
  confirmBtnLoading = false;

  rules = {
    deviceTypeId: { required: true, message: "请输入设备类型" },
    deviceClassId: { required: true, message: "请输入设备分类" },
    deviceName: { required: true, message: "请输入设备型号" },
  };

  firstCategoryList = [];
  secondCategoryList = [];

  mounted() {
    // this.getDeviceTypeList();
    // this.getDeviceSubTypeList();
  }

  deviceTypeChange($event) {
    this.queryForm.deviceClassId = "";
    if (!$event) return;
    getSubDeviceTypeList({ parentId: $event }).then((res) => {
      this.deviceCategoryOptions = res.data || [];
    }).catch(() => {
      this.deviceCategoryOptions = [];
    })
  }


  deviceTypeIdChange($event) {
    if (!$event) return;
    this.formData.deviceClassId = "";
    this.getSubDeviceTypeList({ parentId: $event });
  }

  @Watch("activeName", { immediate: true })
  getTabData(value) {
    if (value == "first") {
      this.getDeviceSubTypeList();
      this.getMainDeviceTypeList();
      this.getDeviceTypeList();
    }
    if (value == "second") {
      this.DeviceTypeCategoryRef?.getDeviceClassList();
    }
  }

  // 获取所有一级分类
  getMainDeviceTypeList() {
    getMainDeviceTypeList().then((res) => {
      this.firstCategoryList = res.data || [];
    })
  }

  getSubDeviceTypeList(params) {
    getSubDeviceTypeList(params).then((res) => {
      this.secondCategoryList = res.data || [];
    }).catch(() => {
      this.secondCategoryList = [];
    })
  }

  getDeviceTypeList() {
    getDeviceTypeList()
      .then((res) => {
        this.deviceTypeList = res.data;
      })
      .catch((err) => {});
  }

  getDeviceSubTypeList() {
    this.showLoading();
    // console.log("this.queryForm.parentId", this.queryForm.parentId ? this.queryForm.parentId : 0)
    const params = {
      ...this.queryForm,
      parentId: !!this.queryForm.parentId ? this.queryForm.parentId : 0,
    }
    getDeviceByName(params)
      .then((res) => {
        this.hideLoading();
        this.deviceSubTypeList = res.data;
        //TODO: 分页待处理
        this.itemTotal = res.data.length;
      })
      .catch((err) => {
        this.hideLoading();
      });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  handleEdit(row = {}) {
    this.dialogVisible = true;
    let { id, deviceTypeId, deviceClassId, deviceClassName,
      deviceName, remark, runtime } = row;
    this.formData.id = id;
    if (id) {
      this.$nextTick(() => {
        this.secondCategoryList = [{ deviceClassName, id: deviceClassId}];
        Object.assign(this.formData, {id, deviceTypeId, deviceClassId, deviceClassName,
          deviceName, remark, runtime})

      })
    }
  }

  dialogClose() {
    // this.formData = deepCopy(initFormData);
    this.dialogVisible = false;
    this.$refs["validateForm"].resetFields();
    this.formData.id = "";
  }
  dialogConfirm() {
    this.$refs["validateForm"].validate((valid) => {
      if (valid) {
        const { id, deviceTypeId, deviceClassId, runtime, remark, deviceName } = this.formData;
        const category2 = this.secondCategoryList?.find(el => el.id == deviceClassId)?.deviceClassName;
        const params = { id, deviceTypeId, deviceClassName: category2, deviceClassId, deviceName, runtime, remark };
        this.confirmBtnLoading = true;
        saveOrUpdateDeviceType(params).then((res) => {
          this.$message.success("操作成功");
          this.getDeviceSubTypeList();
          this.dialogClose();
        }).finally(() => {
          this.confirmBtnLoading = false;
        })
      } else {
        return false;
      }
    });
  }
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
<style lang="scss" scoped>
.content-box {
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff;
}
:deep(.el-tabs) {
  margin-top: 20px;
}
:deep(.el-tabs__header) {
  // border-top: 1px solid #E4E7ED;
}
</style>