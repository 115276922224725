<template>
    <div class="category-type-box">
        <div class="header-button-box">
            <el-button type="plain" class="cotent-btn-big" icon="el-icon-plus" @click="addDevice">新增设备类型</el-button>
        </div>
        <div class="category-content">
            <el-table :data="tableData" style="width: 100%" :default-expand-all="true">
                <el-table-column type="expand">
                  <template #default="{ row }">
                    <el-table style="" :data="row.subClassList" :show-header="false">
                        <el-table-column label="设备分类" prop="deviceClassName">
                          <template #default="{row}">
                            <el-image 
                              fit="cover"
                              style="width: 50px; height: 50px;vertical-align: middle;margin-right: 10px"
                              :src="row.imgUrl" 
                              :preview-src-list="[row.imgUrl]">
                            </el-image>
                            <span>{{row.deviceClassName}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="备注" prop="remark" />
                        <el-table-column label="操作人" prop="modifiedBy" />
                        <el-table-column label="最后操作时间" prop="updateTime" />
                        <el-table-column label="操作" prop="action">
                          <template #default="{row: secondRow}">
                            <el-button @click="editCategory(secondRow, row)" type="text">编辑</el-button>
                          </template>
                        </el-table-column>
                    </el-table>
                  </template>

                </el-table-column>
                <el-table-column width="" label="设备类型" prop="deviceName" />
                <el-table-column label="备注" prop="remark" />
                <el-table-column label="操作人" prop="modifiedBy" />
                <el-table-column label="最后操作时间" prop="updateTime" />
                <el-table-column label="操作">
                    <template #default="{row}">
                        <el-button @click="addDeviceSecond(row)" type="text">添加设备分类</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog
          title="新增设备类型"
          :visible.sync="addDeviceVisible"
          width="460px"
          :before-close="addDeviceClose"
        >
          <div class="edit-box">
            <el-form
              label-position="left"
              label-width="90px"
              :model="addDeviceForm"
              ref="addDeviceFormRef"
              :rules="addDeviceFormRules"
            >
              <el-form-item label="设备类型:" prop="deviceName">
                <el-input
                  v-model="addDeviceForm.deviceName"
                  class="dialogInput"
                  placeholder="请输入设备类型"
                  maxlength="8"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="备注:" prop="remark">
                <el-input
                    type="textarea"
                    v-model="addDeviceForm.remark"
                    class="dialogInput"
                    placeholder="请输入备注"
                    maxlength="64"
                >
                </el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="addDeviceClose">取消</el-button>
              <el-button :loading="submitLoading" type="primary" @click="addDeviceConfirm"
                >提交</el-button
              >
            </div>
          </div>
        </el-dialog>

        <el-dialog
          :title="`${addDeviceSecondForm.id ? '编辑' : '新增'}设备分类`"
          :visible.sync="addDeviceSecondVisible"
          width="460px"
          :before-close="addDeviceSecondClose"
        >
          <div class="edit-box">
            <el-form
              label-position="left"
              label-width="90px"
              :model="addDeviceSecondForm"
              ref="addDeviceSecondFormRef"
              :rules="addDeviceSecondFormRules"
            >
              <el-form-item label="设备类型:" prop="parentDeviceClassName">
                <el-input
                  disabled
                  v-model="addDeviceSecondForm.parentDeviceClassName"
                  class="dialogInput"
                  placeholder="请输入设备类型"
                  maxlength="8"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="设备分类:" prop="deviceClassName">
                <el-input
                  v-model="addDeviceSecondForm.deviceClassName"
                  class="dialogInput"
                  placeholder="请输入设备分类"
                  maxlength="16"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="分类图片:" prop="imgUrl">
                <UploadImg :fileList.sync="addDeviceSecondForm.imgUrl" :limit="1" :size="10" />
                <p class="tip">只支持jpg/png格式</p>
              </el-form-item>
              <el-form-item label="备注:" prop="remark">
                <el-input
                    type="textarea"
                    v-model="addDeviceSecondForm.remark"
                    class="dialogInput"
                    placeholder="请输入备注"
                    maxlength="64"
                >
                </el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="addDeviceSecondClose">取消</el-button>
              <el-button :loading="submitLoading" type="primary" @click="addDeviceSecondConfirm"
                >提交</el-button
              >
            </div>
          </div>
        </el-dialog>
    </div>
</template>
<script>
import { Component, Watch, Mixins, Prop, Ref } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { addDeviceClass, deviceClassList, addDeviceType } from "@/api/deviceType";
import UploadImg from "@/components/uploadImg.vue";

@Component({
  components: { UploadImg }
})
export default class DeviceTypeCategory extends Mixins(loading, tablePagination, Resize) {
    @Ref("addDeviceFormRef") addDeviceFormRef;
    @Ref("addDeviceSecondFormRef") addDeviceSecondFormRef;

    tableData = [];

    addDeviceVisible = false;
    addDeviceForm = {
        deviceName: "",
        remark: "",
    };
    addDeviceFormRules = {
        deviceName: { required: true, message: "请输入设备类型" },
    }

    parentDeviceType = {};
    addDeviceSecondVisible = false;
    addDeviceSecondForm = {
        id: "",
        parentDeviceClassName: "",
        deviceClassName: "",
        imgUrl: [],
        remark: "",
    };
    addDeviceSecondFormRules = {
      deviceClassName: { required: true, message: "请输入设备分类" },
      imgUrl: { required: true, message: "请上传分类图片" }
    }

    submitLoading = false;

    mounted() {
      // this.getDeviceClassList();
    }

    getDeviceClassList() {
      deviceClassList().then((res) => {
        this.tableData = res.data || [];
      })
    }

    addDevice() {
        this.addDeviceVisible = true;
    }

    addDeviceConfirm() {
        this.addDeviceFormRef.validate().then(() => {
            const params = { ...this.addDeviceForm };
            this.submitLoading = true;
            addDeviceType(params).then(() => {
              this.$message.success("操作成功");
              this.addDeviceClose();
              this.getDeviceClassList();
            }).finally(() => this.submitLoading = false)
        })
    }

    addDeviceClose() {
        this.addDeviceVisible = false;
        this.addDeviceFormRef.resetFields();
    }

    addDeviceSecond(row) {
      this.parentDeviceType = { ...row };
        this.addDeviceSecondForm.parentDeviceClassName = row.deviceName;
        this.addDeviceSecondVisible = true;
        this.submitLoading = false;
    }

    editCategory(secondRow, row) {
      this.parentDeviceType = { ...row };
      // parentDeviceClassName: "",
      //   deviceClassName: "",
      //   imgUrl: [],
      //   remark: "",
      this.addDeviceSecondVisible = true;
      this.submitLoading = false;
      this.$nextTick(() => {
        console.log(secondRow.id)
        Object.assign(this.addDeviceSecondForm, {
          id: secondRow.id,
          parentDeviceClassName: row.deviceName,
          deviceClassName: secondRow.deviceClassName,
          imgUrl: secondRow.imgUrl ? [{ url: secondRow.imgUrl }] : [],
          remark: secondRow.remark,
        })
      })


    }

    addDeviceSecondConfirm() {
        this.addDeviceSecondFormRef.validate().then(() => {
          const params = { ...this.addDeviceSecondForm, imgUrl: this.addDeviceSecondForm.imgUrl[0]?.url, parentId: this.parentDeviceType.id };
          this.submitLoading = true;
          addDeviceClass(params).then(() => {
            this.$message.success("操作成功");
            this.addDeviceSecondClose();
            this.getDeviceClassList();
          }).finally(() => this.submitLoading = false)
        })
    }

    addDeviceSecondClose() {
        this.addDeviceSecondVisible = false;
        this.addDeviceSecondForm.id = "";
        this.addDeviceSecondFormRef.resetFields();
        this.submitLoading = false;
    }
}

</script>
<style lang="scss" scoped>
.header-button-box{
    .cotent-btn-big {
        width: 300px;
        border: 1px dashed #0054ff;
    }
}
.category-content{

}
:deep(.el-table__expanded-cell) {
  .el-table {
    // width: calc(100% - 45px);
    // margin-left: 45px;
  }
}
</style>